<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.hub_id" v-validate="{ required: true }" id="hub_id" name="hub_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a hub</option>
                <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
              </select>
              <div class="invalid-feedback">Hub name is required</div>
            </div>
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange"/>
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="form-row mb-2">
          <div class="col-md-12">
            <div class="d-flex float-right" v-if="hub_consumptions.total > 0">
              <a-dropdown placement="bottomRight" :trigger="['click']">
                <button type="button" class="btn btn-outline-success">
                  <i class="fe fe-more-vertical" /> Download
                </button>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a-button class="btn btn-outline-success btn-block mr-2" :loading="pdfDownloadLoader" @click.prevent="pdfDownload"><i class="fa fa-file-pdf-o mr-1"></i> PDF</a-button>
                  </a-menu-item>
                  <a-menu-item>
                    <a-button class="btn btn-outline-success btn-block mr-2" :loading="excelDownloadLoader" @click.prevent="excelDownload"><i class="fa fa-file-excel-o mr-1"></i> Excel</a-button>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-sm table-bordered table-hover text-center" id="hub_consumption">
            <thead>
            <tr>
              <th>#</th>
              <th>Hub Name</th>
              <th>Product Name</th>
              <th>Amount</th>
              <th>Entry Date</th>
              <th>Department</th>
              <th>Employee Name</th>
              <th>Remarks</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(hub_consumption, index) in hub_consumptions.data" :key="index">
              <td>{{ hub_consumptions.from + index}}</td>
              <td>{{ hub_consumption.hub.name }}</td>
              <td>{{ hub_consumption.product.name }}</td>
              <td>{{ hub_consumption.amount }}</td>
              <td>{{ customDate(hub_consumption.entry_date) }}</td>
              <td>{{ hub_consumption.department ? hub_consumption.department.department_name : '...' }}</td>
              <td>{{ hub_consumption.employee_name ? hub_consumption.employee_name : '...' }}</td>
              <td>{{ hub_consumption.consumption_remarks }}</td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="hub_consumptions.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <div class="float-right">
            <pagination class="mt-2" :data="hub_consumptions" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import XLSX from 'xlsx'
import JsPdf from 'jspdf'
import autoTable from 'jspdf-autotable'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      hubs: [],
      hub_consumptions: {},
      loading: false,
      btnLoading: false,
      flag: false,
      pdfDownloadLoader: false,
      excelDownloadLoader: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        hub_id: '',
        date_range: '',
      },
    }
  },
  mounted() {
    this.codes()
  },
  methods: {
    onDateRangeChange(val, dateString) {
      this.search_data.date_range = dateString
    },
    customDate(date) {
      return moment(date).format('LL')
    },
    codes() {
      apiClient.get('api/user/codes')
        .then(response => {
          const data = response.data
          this.hubs = data.hubs
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.flag = true
          this.loading = true
          this.btnLoading = true
          apiClient.post('api/report/hub-consumption/search', this.search_data)
            .then(response => {
              this.loading = false
              this.flag = true
              this.btnLoading = false
              this.hub_consumptions = response.data.hub_consumptions
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    getResults(page = 1) {
      apiClient.post('api/report/hub-consumption/search?page=' + page, this.search_data)
        .then(response => {
          this.hub_consumptions = response.data.hub_consumptions
        })
    },
    onExcelExport(evt) {
      var tbl = document.getElementById('hub_consumption')
      var wb = XLSX.utils.table_to_book(tbl)
      XLSX.writeFile(wb, 'hub-consumption.xlsx')
    },
    onPdfExport() {
      const doc = new JsPdf()
      autoTable(doc, { html: '#hub_consumption' })
      doc.save('hub-consumption.pdf')
    },
    pdfDownload() {
      this.pdfDownloadLoader = true
      apiClient.post('api/hub/consumption/pdf-download', this.search_data, { responseType: 'blob' }).then(response => {
        this.pdfDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'hub_consumption.pdf')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
    excelDownload() {
      this.excelDownloadLoader = true
      apiClient.post('api/hub/consumption/excel-download', this.search_data, { responseType: 'blob' }).then(response => {
        this.excelDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'hub_consumption.xlsx')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
